import React from 'react'

import { Spacer } from 'ethos-design-system'
import PropTypes from 'prop-types'

import styles from './SpacerBlock.module.scss'

const SpacerBlock = ({ moduleData }: any) => {
  const { height } = moduleData

  let SpacerDesktop, SpacerMobile
  switch (height) {
    case '80':
      SpacerDesktop = <Spacer.H80 />
      SpacerMobile = <Spacer.H48 />
      break
    case '48':
      SpacerDesktop = <Spacer.H48 />
      SpacerMobile = <Spacer.H24 />
      break
    case '24':
      SpacerDesktop = <Spacer.H24 />
      SpacerMobile = <Spacer.H16 />
      break
    default:
      SpacerDesktop = <Spacer.H80 />
      SpacerMobile = <Spacer.H48 />
  }

  return (
    <>
      <div className={styles.desktop}>{SpacerDesktop}</div>
      <div className={styles.mobile}>{SpacerMobile}</div>
    </>
  )
}

SpacerBlock.propTypes = {
  moduleData: PropTypes.shape({
    type: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    experimentKey: PropTypes.string,
  }),
}

export default SpacerBlock
